import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

interface IThreeViewProps {}

export const ThreeViewMobile: React.FC<IThreeViewProps> = (props: IThreeViewProps) => {
    const [state, setState] = useState({
        wd: window.innerWidth,
        wh: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio > 1 ? window.devicePixelRatio : 1,
        isLoaded: !1,
        spFlg: !1,
        obj: {
            time: 0,
            colorRedMinValue: 0.02,
            colorBlueMinValue: 0.1,
            colorYellowMinValue: 0.02,
        },
        colorMaxValue: 0.5,
        colorRedMinValue: 0.02,
        colorBlueMinValue: 0.1,
        colorYellowMinValue: 0.02,
        isOpenModal: !1,
        selectColor: '',
        colorList: '',
        colorCode: {
            red:
                '#ffdcdc,#f7c8c8,#f9b0b0,#fe1235,#ffaaaa,#f1a3a3,#f79797,#f50126,#ff4646,#ec8484,#f36767,#e00123,#ff0000,#e45252,#f04444,#c9011f,#dc0000,#df3737,#ee2b2b,#af011c,#be0000,#d52222,#e61313,#9e0118,#a00000,#bd1e1e,#d21111,#8f0116,#820000,#961818,#bd0f0f,#780113,#640000,#791313,#a20d0d,#650110,#460000,#4f0d0d,#8f0c0c,#56010e,#fac9d3,#f4ccd9,#fb8a8a,#d03f20,#f8b1df,#edabc2,#fa5a5a,#bc381d,#f491a5,#e382a2,#f93535,#ab341b,#ef637e,#da5883,#f91c1c,#972e17,#ea3559,#d23367,#f81212,#872814,#da163e,#bd2b5a,#eb0707,#6b2010,#c11336,#ac2652,#ce0606,#591b0d,#a5102e,#942146,#c00505,#4d160b,#860d25,#651630,#a60404',
            blue:
                '#acacff,#d8d3fa,#cee7ff,#c1ebff,#6e6eff,#b0a7f5,#9dceff,#9bdeff,#4646ff,#8070ef,#6fb7ff,#5bcaff,#1e1eff,#5944ea,#51a8ff,#28b9ff,#0000ff,#462fe8,#1e8eff,#00acff,#0000d2,#2f17d0,#0074e8,#0099e3,#0000aa,#2613ac,#005ebb,#007ab5,#000082,#20108f,#00509f,#005f8c,#000064,#160b64,#003a75,#004364,#00003c,#110948,#00264d,#003046,#dbdcf4,#ccd2f2,#ced7e3,#d9e3ee,#bdbeec,#a9b3e9,#a4b4ca,#afc5dc,#9c9ee2,#8795e0,#7e95b4,#95b3d0,#7476d6,#586ad3,#6480a6,#779dc4,#595cce,#3c51cc,#526c8f,#5787b7,#3639ba,#3146bb,#4e6687,#4878a8,#2c2f98,#2b3da4,#3d5069,#3e6791,#222475,#233285,#324156,#315171,#17184f,#18235c,#243040',
            yellow:
                '#ffffc8,#fafed8,#fef8c9,#fbf8dd,#ffff96,#f5fdae,#fdf099,#f9f4ca,#ffff64,#eefc72,#fcea6b,#f7f0b9,#ffff00,#e6fa2c,#fbe340,#f4ea9d,#e1e100,#dcf404,#fadd16,#f1e487,#c8c800,#cfe604,#f1d305,#ecda5e,#afaf00,#abc003,#dfc404,#e9d33f,#969600,#8f9f02,#c2ab03,#e7cf27,#787800,#738002,#a48f02,#dac118,#5a5a00,#535c01,#786901,#d0b917,#ffffe1,#fdffe1,#ecebc6,#d0db00,#ffffc8,#fcffce,#e2e1a9,#c1cc00,#ffffaa,#fcffce,#d9d78c,#b1bb00,#ffff82,#faff93,#ccca66,#a8b000,#ffff6f,#f9ff75,#c0bd45,#949b00,#ffff4d,#f7ff53,#a4a037,#848a00,#ffff1e,#f5ff3e,#8c8a2f,#757b00,#f0f000,#f3ff17,#716f26,#676c00,#d2d200,#e7f200,#616021',
        },
    });

    const canvasRef = useRef<HTMLDivElement>();
    const sceneRef = useRef<THREE.Scene>();

    const meshRef = useRef<THREE.Mesh>();
    const cameraRef = useRef<THREE.PerspectiveCamera>();
    const rendererRef = useRef<THREE.WebGLRenderer>();

    

    const render = () => {
        const ratio = window.devicePixelRatio > 1 ? 0.01 : 0.05;
        const loop = () => {
            const mesh = meshRef.current;
            const renderer = rendererRef.current;
            mesh.material['uniforms'].time.value = mesh.material['uniforms'].time.value + (ratio % 1);
            
            renderer.render(sceneRef.current, cameraRef.current);
            requestAnimationFrame(loop);
        };
        loop();
    };

    const shader = () => {
        const material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms: {
                time: {
                    type: 'f',
                    value: state.obj.time,
                },
                colorBlue: {
                    type: 'f',
                    value: state.obj.colorBlueMinValue,
                },
                colorRed: {
                    type: 'f',
                    value: state.obj.colorRedMinValue,
                },
                colorYellow: {
                    type: 'f',
                    value: state.obj.colorYellowMinValue,
                },
                resolution: {
                    value: new THREE.Vector2(state.wd  * state.devicePixelRatio, state.wh* 0.35 * state.devicePixelRatio),
                },
                resolution_circle: {
                    value: new THREE.Vector2(state.wd * state.devicePixelRatio, state.wh*0.6 * state.devicePixelRatio),
                }
            },
            vertexShader: [
                'precision mediump float;',
                'uniform float time;',
                'varying vec2 vUv;',
                'void main() {',
                'vUv = uv;',
                'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
                '}',
            ].join('\n'),
            fragmentShader: [
                'precision mediump float;',
                'uniform float time;',
                'uniform float colorBlue;',
                'uniform float colorRed;',
                'uniform float colorYellow;',
                'uniform vec2 resolution;',
                'uniform vec2 resolution_circle;',
                'varying vec2 vUv;',
                'float circle = 0.05;',
                'float getPos(vec2 vPos, float fColorType, float fColor, float fTime) {',
                'return fColorType / abs(length(vPos) - 0.8 + cos(fColor + time * fTime) * circle);',
                '}',
                'void main() {',
                'vec2 pos = (gl_FragCoord.xy * 1.4 - resolution*1.0) / min(resolution.x, resolution.y);',
                'vec2 pos_circle = (gl_FragCoord.xy * 2.0 - resolution_circle) / min(resolution_circle.x, resolution_circle.y);',
                'vec3 colorOuterPat = vec3(1.0,0.1,1.0);',
                'vec3 colorBluePat = vec3(0.0,0.2,1.0);',
                'vec3 colorRedPat = vec3(1.0,0.0,0.3);',
                'vec3 colorYellowPat = vec3(0.9,0.5,0.0);',
                'float outer1 = sin(pos.x * pos.y) * 10.0;',
                'float outerPos = 0.1 / abs(dot(pos.x, pos.y) - 1.0 + sin(outer1 + time) * 0.01);',
                'colorOuterPat = (0.5 + (0.2 + sin(time)) * 0.5) * colorOuterPat;',
                'float blue = sin(pos_circle.x * pos_circle.y) * 10.0;',
                'float bluePos = getPos(pos_circle, colorBlue, blue, 10.0);',
                'colorBluePat = (1.0 + sin(blue + time) * 0.1) * colorBluePat;',
                'float red = sin((pos_circle.x + 0.25) * (pos_circle.y)) * 12.0;',
                'float redPos = getPos(pos_circle, colorRed, red, 11.0);',
                'colorRedPat = (1.0 + sin(red + time + 0.05) * 0.1) * colorRedPat;',
                'float yellow = sin((pos_circle.x) * (pos_circle.y - 0.5)) * 10.0;',
                'float yellowPos = getPos(pos_circle, colorYellow, yellow, 12.0);',
                'colorYellowPat = (1.0 + sin(yellow + time + 0.1) * 0.1) * colorYellowPat;',
                'vec3 colorList = outerPos * colorOuterPat + bluePos * colorBluePat + redPos * colorRedPat + yellowPos * colorYellowPat;',
                'gl_FragColor = vec4(colorList, 1.0);',
                '}',
            ].join('\n'),
        });
        const geometry = new THREE.PlaneBufferGeometry(state.wd, state.wh);
        meshRef.current = new THREE.Mesh(geometry, material);
        const mesh = meshRef.current;
        mesh.position.set(0, 0, 0);
        sceneRef.current.add(mesh);
        
        render();
    };

    const init = () => {
        const { wd, wh } = state;
        sceneRef.current = new THREE.Scene();
        const ratio = wd / wh;
        cameraRef.current = new THREE.PerspectiveCamera(45, ratio, 1, 1e3);
        const camera = cameraRef.current;
        if (state.spFlg) {
            camera.position.set(0, 0, 400);
        } else {
            camera.position.set(0, 0, 600);
        }
        camera.lookAt(new THREE.Vector3(0, 0, 200));

        rendererRef.current = new THREE.WebGLRenderer({
            alpha: !0,
            antialias: !0,
        });
        const renderer = rendererRef.current;
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(wd, wh*0.6);
        canvasRef.current.appendChild(renderer.domElement);
        shader();
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={canvasRef}></div>;
};

export default ThreeViewMobile;
