import React from 'react';
import "./style.less"

import ThreeViewMobile from '@/components/ThreeView/ThreeViewMobile';

//const logo = require("../../../public/default.png")

export default function Home() {
    return (
        <div style={{
            backgroundColor: "#0A081B",
            height: "100vh",
            overflowY: "auto"
        }} className="scroll-container">
            <div style={{ position: "relative", zIndex: 99 }}>


                <ThreeViewMobile />
                <div style={{
                    position: "absolute",
                    top: "25%",
                    left: "50%",
                    width: "62%",
                    height: 200,
                    transform: "translate(-50%)"
                }}
                >
                    <div style={{
                        height: 104,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <img src={require("../../images/default.png")} style={{ width: 175, }} alt="" />
                    </div>

                    <div style={{
                        marginTop: 4,
                        textAlign: "right",
                        fontSize: 14,
                        color: "#8B49FF",
                        lineHeight: "18px",
                        letterSpacing: "1px",
                        fontWeight: "bold"
                    }}>
                        sounmate
                </div>

                    <div style={{
                        fontSize: 14,
                        color: "#8B49FF",
                        lineHeight: "18px",
                        letterSpacing: "1px",
                        textAlign: "right",
                        marginTop: 16,
                        fontWeight: "bold"
                    }}>
                        <span>Would you be my </span>
                        <span style={{
                            textDecoration: "line-through"
                        }}>soulmate</span>
                        <span>?</span>
                    </div>
                </div>

                <div style={{
                    width: 343,
                    position: "absolute",
                    bottom: -8,
                    left: "50%",
                    transform: "translate(-50%)"
                }}>
                    <div style={{
                        display: "flex"
                    }}>
                        <div style={{
                            width: 162,
                            marginRight: 19,
                            display: "flex",
                            background: "#0A081B",
                            borderRadius: 28,
                            opacity: 0.7,
                            padding: 12
                        }}>
                            <div style={{ marginRight: "2vh" }}>
                                <img src={require("../../images/apple@2x.png")} style={{ width: 28 }} alt="" />
                            </div>
                            <div style={{
                                lineHeight: "15px",
                                color: "#8B49FF",
                                fontSize: 12,
                                letterSpacing: "0.86px"
                            }}>
                                <div>App store</div>
                                <div style={{ wordBreak: "break-all" }}>free download</div>
                            </div>
                        </div>
                        <div style={{
                            width: 162,
                            display: "flex",
                            background: "#0A081B",
                            borderRadius: 28,
                            opacity: 0.7,
                            padding: 12
                        }}>
                            <div style={{ marginRight: "2vh" }}>
                                <img src={require("../../images/android@2x.png")} style={{ width: 28 }} alt="" />
                            </div>
                            <div style={{
                                lineHeight: "15px",
                                color: "#8B49FF",
                                fontSize: 12,
                                letterSpacing: "0.86px"
                            }}>
                                <div>Android</div>
                                <div>free download</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div style={{
                marginTop: 83,
                width: 185,
                color: "#8B49FF",
                fontSize: 14,
                letterSpacing: "1.14px",
                lineHeight: "20px",
                marginLeft: 82
            }}>
                <div>Ready to play?</div>
                <div>Sounmate is a place</div>
                <div>where talking too much</div>
                <div>makes you rich</div>
            </div>
            <div style={{
                margin: "23px 33px 0 32px",
                paddingBottom: 153
            }}>
                <img src={require("../../images/sounmate_iphone2@2x.png")} style={{ width: "100%" }} alt="" />
            </div>
        </div>
    );
}
