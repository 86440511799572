import * as React from 'react';
import { renderRoutes } from 'react-router-config';

import useApp from '@/components/AppProvider/useApp';
import SplashScreen from '@/components/SplashScreen';

import routes from './config';

export default function MobilePages() {
    const app = useApp();

    if (app.isLoading) {
        return <SplashScreen />;
    }
    return <React.Fragment>{renderRoutes(routes)}</React.Fragment>;
}
