import isFunction from 'lodash/isFunction';
import startsWith from 'lodash/startsWith';
import { observable } from 'mobx';

export type ExternalLogin = {
    login_provider: string;
    profile: {
        email_address: string;
        open_id: string;
        phone_number: string;
        qq_number: string;
        union_id: string;
        username: string;
        wechat_id: null;
    };
    provider_key: string;
};

export class SessionUser {
    @observable address: {
        city: string;
        country: string;
        detail: string;
        district: string;
        province: string;
    };

    @observable avatar: string;

    @observable gender = '';
    @observable name: string;
    @observable nickname: string;
    @observable birthday: string;

    @observable constellation: string;

    creator_user_id: string;

    extend_data: { [key: string]: any };

    language: string;
    remark: string;
    serial_number: string;
    surname: string;
    user_id: string;
    last_update_nickname_time: string;
    creation_time: string;

    external_logins: {
        WeChatMiniProgram: ExternalLogin;
        [name: string]: ExternalLogin;
    };

    id: string;

    constructor(private raw_data) {
        this.update(raw_data);
    }

    update(user) {
        for (const key in user) {
            this.raw_data[key] = user[key];
            const prop = this[key];
            if (prop && isFunction(prop.update)) {
                prop.update(user[key]);
            }
            this[key] = user[key];
        }
        return this.raw_data;
    }
    get isValidNickName() {
        return !startsWith(this.nickname, 'sounmate_');
    }
    get isValidInfo() {
        return this.avatar && this.birthday && this.address;
    }
    get isValidSex() {
        return this.gender && this.gender != 'Unknown';
    }
    get isValid() {
        return this.isValidNickName && this.isValidSex && this.isValidInfo;
    }
}

export default SessionUser;
