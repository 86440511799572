import { Events } from '../../../libs/events';

let lastAskId = 0;

const getAskId = () => {
    lastAskId = lastAskId + 1;
    return `ask_${new Date().getTime()}_${lastAskId}`;
};

const askEvents = new Events();
const askIds: { [name: string]: string[] } = {};
export const AskCenter = {
    on(name, callback) {
        return askEvents.on(name).then(callback);
    },

    off(name, callback) {
        return askEvents.removeListener(name, callback);
    },

    callback(name, data) {
        const asks = (askIds[name] = askIds[name] || []);
        for (const askId of asks) {
            askEvents.trigger(askId, data);
        }
        askIds[name] = [];
    },

    ask(name) {
        return new Promise(resolve => {
            const askId = getAskId();

            askIds[name] = askIds[name] || [];
            askIds[name].push(askId);

            const sub = askEvents.on(askId).then(result => {
                sub.off();
                resolve(result);
            });
            askEvents.trigger(name, askId);
        });
    },
};

export default AskCenter;
