import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './components/AppProvider';
import './di/config';
import Pages from './pages';
import './styles/App.css';
import MobilePages from './mobilePages';

export default function App() {

    const isMobile = navigator.userAgent.match(
        /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i
    ) !== null;


    return (
        <AppProvider>
            <BrowserRouter>
                {
                    isMobile ?
                        <MobilePages></MobilePages>
                        :
                        <Pages />
                }

            </BrowserRouter>
        </AppProvider>
    );
}
