import di from '../../../libs/di';
import MemoryCache from '../../../libs/MemoryCache';
import SessionBase from './session';

type ReadyCallbacks = {
    after?(isAuthenticated: boolean): any;
};

@di.Register('sessionService')
export class SessionService extends SessionBase {
    ready = (callbacks?: ReadyCallbacks) => {
        return MemoryCache.getAsync('SessionServiceReady', () => {
            return this.init().then(() => {
                if (this.isAuthenticated) {
                    this.updateLoginedUser();
                } else if (callbacks) {
                    return callbacks.after && callbacks.after(false);
                }
            });
        });
    };
}

export default SessionService;
