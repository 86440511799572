import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import { HttpRequestBuilder } from './index';
import { IHttpFactory } from './interface';

export class HttpFactory implements IHttpFactory {
    constructor(private baseUrl: string) {}

    create() {
        return new HttpRequestBuilder();
    }

    url(api): HttpRequestBuilder {
        const url = `${trimEnd(this.baseUrl, '/')}/${trimStart(api, '/')}`;
        return new HttpRequestBuilder(url);
    }
}
export default HttpFactory;
