import isArray from 'lodash/isArray';

import { IAsyncKeyValueStorage } from '../IAsyncKeyValueStorage';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class LocalAsyncKeyValueStorage implements IAsyncKeyValueStorage {
    constructor(private __STORE_NAME__) {
        if (!__STORE_NAME__) {
            throw new Error('必须设置存储名称');
        }
    }

    async keys() {
        const len = localStorage.length;
        const keys = [];
        for (let i = 0; i < len; i++) {
            const key = localStorage.key(i);
            const [name, _key] = key.split('/');
            if (name == this.__STORE_NAME__) {
                keys.push(_key);
            }
        }
        return keys;
    }

    async getAsync(key: string, defaultValue = null) {
        if (/\//.test(key)) {
            throw new Error("key不能包含'/'");
        }
        const _key = `${this.__STORE_NAME__}/${key}`;
        return localStorage.getItem(_key) || defaultValue;
    }

    async setAsync(key: string, value: any) {
        if (/\//.test(key)) {
            throw new Error("key不能包含'/'");
        }
        const _key = `${this.__STORE_NAME__}/${key}`;
        localStorage.setItem(_key, value);
        return value;
    }

    async batchSetObject(obj: any, keyProp?: any, valueProp?: any) {
        const tasks: any[] = [];
        if (isArray(obj)) {
            for (const item of obj) {
                const key = item[keyProp];
                if (!key || key == '') {
                    continue;
                }
                let val = item;
                if (valueProp) {
                    val = item[valueProp];
                }
                tasks.push(this.setObjectAsync(key, val));
            }
        } else {
            for (const key in obj) {
                tasks.push(this.setObjectAsync(key, obj[key]));
            }
        }
        return Promise.all(tasks);
    }

    async setObjectAsync(key: string, value: any) {
        await this.setAsync(key, JSON.stringify(value));
        return value;
    }

    async getObjectAsync(key: string) {
        const str = await this.getAsync(key, null);
        if (str) {
            return JSON.parse(str);
        }
        return null;
    }

    async setObjectPropertyAsync(key: string, propertyName: string, value: any) {
        let obj = await this.getObjectAsync(key);
        if (!obj) {
            obj = {};
        }
        obj[propertyName] = value;
        return this.setObjectAsync(key, obj);
    }

    async getObjectPropertiesAsync(key: string, ...propertyNames: any[]) {
        let obj = await this.getObjectAsync(key);
        if (!obj) {
            obj = {};
        }
        const result = {};
        for (const propertyName of propertyNames) {
            result[propertyName] = obj[propertyName];
        }
        return result;
    }

    async getObjectValueAsync(key: string, propertyName: string) {
        const obj = await this.getObjectAsync(key);
        if (obj) {
            return obj[propertyName];
        }
        return null;
    }

    async removeAsync(key: string) {
        const _key = `${this.__STORE_NAME__}/${key}`;
        localStorage.removeItem(_key);
    }

    batchRemoveAsync(keys) {
        const tasks: any = [];
        for (const key of keys) {
            tasks.push(this.removeAsync(key));
        }
        return Promise.all(tasks);
    }
    async clearAsync() {
        const keys: any[] = await this.keys();
        const removeKeys: any[] = [];
        for (const key of keys) {
            const name = key.split('/')[0];
            if (name == this.__STORE_NAME__) {
                removeKeys.push(key);
            }
        }
        this.batchRemoveAsync(removeKeys);
        return keys;
    }
}

export default LocalAsyncKeyValueStorage;
