/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
export enum LogLevel {
    info = 4,
    debug = 3,
    warn = 2,
    error = 1,
}

let colorIndex = -1;

const colors = ['#0000CC', '#0000FF', '#0033CC', '#0033FF', '#0066CC', '#0066FF'];

function getNextColor() {
    colorIndex += 1;
    if (colorIndex >= colors.length) {
        colorIndex = 0;
    }
    return colors[colorIndex];
}

export interface ILogger {
    info(message, ...args);
    debug(message, ...args);
    warn(message, ...args);
    error(message, ...args);
    exception(ex);
}
// eslint-disable-next-line no-undef
const isDev = true; //process.env.NODE_ENV == 'development' || __DEV__;

let adapder = console;

export const setAdapder = logAdapder => {
    adapder = logAdapder;
};

export class Logger {
    private tagColor;

    constructor(public tag = 'default', public logLevel = isDev ? LogLevel.info : LogLevel.error) {
        this.tagColor = `color:${getNextColor()}`;
    }

    get useColor() {
        if (typeof navigator !== 'undefined' && /Chrome/.test(navigator.userAgent)) {
            return true;
        }
        return false;
    }

    setLevel(level: LogLevel) {
        this.logLevel = level;
    }
    info(message, ...args) {
        if (this.logLevel < LogLevel.info) {
            return;
        }
        if (this.useColor) {
            adapder.log(`%c[INFO]%c[${this.tag}]`, 'color:#ccc', this.tagColor, message, ...args);
        } else {
            adapder.log(`[INFO][${this.tag}]`, message, ...args);
        }
    }
    debug(message, ...args) {
        if (this.logLevel < LogLevel.debug) {
            return;
        }
        if (this.useColor) {
            adapder.log(`%c[DEBUG]%c[${this.tag}]`, 'color:#00f', this.tagColor, message, ...args);
        } else {
            adapder.log(`[DEBUG][${this.tag}]`, message, ...args);
        }
    }

    warn(message, ...args) {
        if (this.logLevel < LogLevel.warn) {
            return;
        }
        adapder.warn(`[WARN][${this.tag}]`, message, ...args);
    }
    error(message, ...args) {
        adapder.error(`[ERROR][${this.tag}]`, message, ...args);
    }

    exception(ex) {
        adapder.error('发生异常', ex);
    }

    static tag(tag) {
        return new Logger(tag);
    }
}

export default Logger;

export function logger(tagName = 'default') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function(target, propertyKey: string, desc?): any {
        const options = {
            get() {
                const k = `__logger__`;
                if (!target[k]) {
                    if (target.constructor && target.constructor.name) {
                        tagName = target.constructor.name;
                    }
                    target[k] = new Logger(tagName);
                }
                return target[k];
            },
            set() {
                throw new Error('Not allowed');
            },
            enumerable: true,
            configurable: true,
        };
        if (desc) {
            return options;
        }
        Object.defineProperty(target, propertyKey, options);
        return;
    };
}

export function log(_log) {
    return function(_target, name, descriptor) {
        const method = descriptor.value;
        const _logger = _target.logger || Logger.tag(name);
        descriptor.value = function(...args) {
            _logger.info('开始', _log, args);
            try {
                const result = method.apply(this, args);
                _logger.info('成功', _log, result);
                return result;
            } catch (ex) {
                _logger.info('失败', _log, ex);
                throw ex;
            }
        };
    };
}

export function logAsync(_log) {
    return function(_target, name, descriptor) {
        const method = descriptor.value;
        const _logger = _target.logger || Logger.tag(name);
        descriptor.value = function(...args) {
            _logger.info('开始', _log, args);
            return method
                .apply(this, args)
                .then(result => {
                    _logger.info('成功', _log, result);
                    return result;
                })
                .catch(err => {
                    _logger.info('失败', _log, err);
                    return Promise.reject(err);
                });
        };
    };
}
