import { ApiNames } from '../config/interface';
import di from '../libs/di';
import ConfigService from '../services/common/config';

export function api(configName: ApiNames) {
    return function(target, propertyKey, desc?): any {
        const options = {
            get() {
                const key = `$$__http$${propertyKey}`;
                if (!target[key]) {
                    const configService = di.getInstance<ConfigService>(ConfigService);
                    const apiHosts = configService.get('api');
                    if (!apiHosts[configName]) {
                        throw new Error('api:未配置接口->' + configName);
                    }
                    target[key] = di.Resolve('http', apiHosts[configName]);
                }
                return target[key];
            },
            set() {
                throw new Error('Not allowed');
            },
            enumerable: true,
            configurable: true,
        };

        if (desc) {
            return options;
        }
        Object.defineProperty(target, propertyKey, options);
        return;
    };
}
export default api;
