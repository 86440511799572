import di from '@/shared/libs/di';
import { HttpFactory } from '@/shared/libs/http/factory';
import AsyncKeyValueStorage from '@/shared/libs/storage/localStorage/AsyncKeyValueStorage';
import SyncKeyValueStorage from '@/shared/libs/storage/localStorage/SyncKeyValueStorage';
import ConfigService from '@/shared/services/common/config';

import appConfig from '../config/app';

const { container } = di;

container.bind('deviceId').toValue('');

container.bind('configService').to(ConfigService).params(appConfig);
container.bind('http').to(HttpFactory).isRequestScope();
container.bind('asyncStorage').to(AsyncKeyValueStorage).isRequestScope();
container.bind('syncStorage').to(SyncKeyValueStorage).isRequestScope();
