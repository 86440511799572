// import AsyncComponent from '../components/AsyncComponent';
// import AuthComponent from '../components/AuthComponent';

const routes = [
    {
        path: '/',
        exact: true,
        component: require('./Home').default,
    },
    {
        component: require('./NotFound').default,
    },
];
export default routes;
