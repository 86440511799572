import ChatUsersApi from '../../../api/chat/common/chatusers';
import MatesApi from '../../../api/content/common/mates';
import SoulsApi from '../../../api/content/common/souls';
import UsersApi from '../../../api/identity/common/users';
import di from '../../../libs/di';
import sleep from '../../../libs/sleep';
import { ICurrentUserService } from './interface';

@di.Register('meService')
export class MeService implements ICurrentUserService {
    @di.Property(MatesApi) private matesApi: MatesApi;

    @di.Property(SoulsApi) private soulsApi: SoulsApi;

    @di.Property(UsersApi) private usersApi: UsersApi;

    @di.Property(ChatUsersApi) private chatUsersApi: ChatUsersApi;

    private async getMeUser(retry = 3, next_retry_time = 1000) {
        try {
            const res = await this.usersApi.me().get();
            return res.data.result;
        } catch (ex) {
            if (ex.status == 404) {
                if (retry > 0) {
                    setTimeout(() => {
                        return this.getMeMate(retry - 1, next_retry_time);
                    }, next_retry_time);
                }
                return null;
            }
            throw ex;
        }
    }
    private async getMeMate(retry = 3, next_retry_time = 1000) {
        try {
            const res = await this.matesApi.me().get();
            return res.data.result;
        } catch (ex) {
            if (ex.status == 404) {
                if (retry > 0) {
                    setTimeout(() => {
                        return this.getMeMate(retry - 1, next_retry_time);
                    }, next_retry_time);
                }
                return null;
            }
            throw ex;
        }
    }
    private async getMeSoul(retry = 3, next_retry_time = 1000) {
        try {
            const res = await this.soulsApi.me().get();
            return res.data.result;
        } catch (ex) {
            if (ex.status == 404 && retry > 0) {
                await sleep(next_retry_time);
                return this.me(retry - 1, next_retry_time * 2);
            }
            throw ex;
        }
    }

    private async getMeChat(retry = 3, next_retry_time = 1000) {
        try {
            const res = await this.chatUsersApi.me().get();
            return res.data.result;
        } catch (ex) {
            if (ex.status == 404 && retry > 0) {
                await sleep(next_retry_time);
                return this.me(retry - 1, next_retry_time * 2);
            }
            throw ex;
        }
    }

    async me(retry = 3, next_retry_time = 1000) {
        const [user, soul, mate, chat_user] = await Promise.all([
            this.getMeUser(retry, next_retry_time),
            this.getMeSoul(retry, next_retry_time),
            //Mate只获取一次
            this.getMeMate(0, next_retry_time),
            //获取当前用户的聊天用户
            this.getMeChat(retry, next_retry_time),
        ]);
        soul.chat_user = chat_user;
        soul.user = user;
        if (mate) {
            soul.mate = mate;
        }
        return soul;
    }
}

export default MeService;
