import di from '../di';
import { IAsyncKeyValueStorage } from './IAsyncKeyValueStorage';
import { IKeyValueStorageManager } from './IKeyValueStorageManager';
import { ISyncKeyValueStorage } from './ISyncKeyValueStorage';

const cached = {};

export class KeyValueStorageManager implements IKeyValueStorageManager {
    getAsyncStorage(storeName, dbName): IAsyncKeyValueStorage {
        const skey = `${storeName}${dbName}`;
        if (!cached[skey]) {
            cached[skey] = di.Resolve('asyncStorage', storeName, dbName); // new WebAsyncKeyValueStorage(storeName);
        }
        return cached[skey];
    }
    getSyncStorage(storeName, dbName): ISyncKeyValueStorage {
        const skey = `sync${storeName}`;
        if (!cached[skey]) {
            cached[skey] = di.Resolve('syncStorage', storeName, dbName);
        }
        return cached[skey];
    }
}
export const storageManager = new KeyValueStorageManager();

export const storage = {
    sync(storeName?, dbName = 'default') {
        return function(target, propertyKey, desc?): any {
            const options = {
                get() {
                    const key = storeName || propertyKey;
                    return storageManager.getSyncStorage(key, dbName);
                },
                set() {
                    throw new Error('Not allowed');
                },
                enumerable: true,
                configurable: true,
            };
            if (desc) {
                return options;
            }
            Object.defineProperty(target, propertyKey, options);
        };
    },
    async(storeName?: string, dbName = 'default') {
        return function(target, propertyKey, desc?): any {
            const options = {
                get() {
                    const key = storeName || propertyKey;
                    return storageManager.getAsyncStorage(key, dbName);
                },
                set() {
                    throw new Error('Not allowed');
                },
                enumerable: true,
                configurable: true,
            };
            if (desc) {
                return options;
            }
            Object.defineProperty(target, propertyKey, options);
        };
    },
};

export function useAsyncStorage(storeName, dbName = 'default') {
    return storageManager.getAsyncStorage(storeName, dbName);
}

export function useSyncStorage(storeName, dbName = 'default') {
    return storageManager.getSyncStorage(storeName, dbName);
}

export default storage;
