import React from 'react';

import ThreeView from '@/components/ThreeView';

//const logo = require("../../../public/default.png")

export default function Home() {
    return (
        <div style={{
            position: "relative",
            fontFamily: "Rubrik",
            minWidth: 1320
        }}>
            <ThreeView />
            <div style={{
                width: "24%",
                height: "36%",
                position: "absolute",
                top: "27%",
                left: "18%",
                //overflow: "hidden"
            }}>
                <div style={{
                    height: 133,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img src={require("../../images/default.png")} style={{ width: 222, }} alt="" />
                </div>
                <div style={{
                    marginTop: 15,
                    textAlign: "right",
                    paddingRight: 9,
                    fontSize: 21,
                    color: "#8B49FF",
                    lineHeight: "27px",
                    letterSpacing: "1.5px",
                    fontWeight: "bold"
                }}>
                    sounmate
                </div>

                <div style={{
                    fontSize: 21,
                    color: "#8B49FF",
                    lineHeight: "27px",
                    letterSpacing: "1px",
                    textAlign: "right",
                    marginTop: 21,
                    fontWeight: "bold"
                }}>
                    <span>Would you be my </span>
                    <span style={{
                        textDecoration: "line-through"
                    }}>soulmate</span>
                    <span>?</span>
                </div>

                <div style={{
                    display: "flex",
                    marginTop: 49
                }}>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        paddingLeft: 0
                    }}>
                        <div style={{ marginRight: 21 }}>
                            <img src={require("../../images/apple@2x.png")} style={{ width: 28 }} alt="" />
                        </div>
                        <div style={{
                            lineHeight: "15px",
                            color: "#8B49FF",
                            fontSize: 12,
                            letterSpacing: "0.86px"
                        }}>
                            <div>App store</div>
                            <div>free download</div>
                        </div>
                    </div>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        paddingLeft: 14
                    }}>
                        <div style={{ marginRight: 21 }}>
                            <img src={require("../../images/android@2x.png")} style={{ width: 28 }} alt="" />
                        </div>
                        <div style={{
                            lineHeight: "15px",
                            color: "#8B49FF",
                            fontSize: 12,
                            letterSpacing: "0.86px"
                        }}>
                            <div>Android</div>
                            <div>free download</div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{
                position: "absolute",
                top: "23%",
                right: "5%",
                width:"30%"
            }}>
                <div style={{ position: "relative" }}>
                    <img src={require("../../images/sounmate_iphone2@2x.png")} style={{ width: "100%",maxWidth:500 }} alt="" />
                    <div style={{
                        color: "#8B49FF",
                        fontSize: 16,
                        letterSpacing: "1.14px",
                        lineHeight: "20px",
                        position: "absolute",
                        top: 16,
                        left: -72
                    }}>
                        <div>Ready to play?</div>
                        <div>Sounmate is a place</div>
                        <div>where talking too much</div>
                        <div>makes you rich</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
