import { observable } from 'mobx';

import AccountsApi from '../../../api/identity/common/accounts';
import di from '../../../libs/di';
import { Events } from '../../../libs/events';
import { ILogger, logAsync, logger } from '../../../libs/logger';
import MemoryCache from '../../../libs/MemoryCache';
import AskCenter from '../ask';
import ConfigService from '../config';
import MeService from '../me';
import { ICurrentUserService } from '../me/interface';
import TokenService from '../token';
import { TokenObject } from '../token/interface';
import { ISessionUserService } from './interface';
import SessionUser from './user';

const LOGINED_USER = 'logined-user';

export type AskLoginResult = {
    status: 'ok' | 'error' | 'needProfile';
};

export class SessionBase extends Events {
    @logger() private logger: ILogger;

    @di.Property(AccountsApi) private accountsApi: AccountsApi;
    @di.Property(ConfigService) private configService: ConfigService;
    @di.Property(TokenService) private tokenService: TokenService;

    @di.Property(MeService) private meService: ICurrentUserService;

    @observable isAuthenticated: boolean = null;

    private user: any;

    private external_logins = null;

    ready: () => Promise<any>;

    private async getSavedUser() {
        const localUser = await this.configService.readAsync(LOGINED_USER, null);
        if (localUser) {
            const token = await this.tokenService.getAccessToken();
            if (token) {
                return localUser.data;
            }
        }
        return null;
    }

    private saveLoginedUser(user) {
        return this.configService.saveAsync(LOGINED_USER, {
            data: user,
        });
    }

    private removeSavedUser() {
        return this.configService.removeAsync(LOGINED_USER);
    }

    @logAsync('同步当前用户信息')
    private async syncUser() {
        try {
            const me = await this.meService.me(3);
            this.saveLoginedUser(me);
            const sessionUserService = di.tryResolve<ISessionUserService>('sessionUserService');
            if (sessionUserService) {
                this.user = sessionUserService.get(me);
            } else {
                console.warn('未实现SessionUserService,使用默认');
                if (this.user && this.user.id == me.id) {
                    this.user.update(me);
                } else {
                    this.user = new SessionUser(me);
                }
            }

            this.isAuthenticated = true;
        } catch (ex) {
            if (ex.status == 401 || ex.status == 403 || ex.status == 404) {
                await this.tokenService.clear();
                await this.removeSavedUser();
                this.isAuthenticated = false;
            }
            throw ex;
        }
    }

    init() {
        return MemoryCache.getAsync('sessionServiceInit', async () => {
            const user = await this.getSavedUser();
            if (user) {
                const sessionUserService = di.tryResolve<ISessionUserService>('sessionUserService');
                if (sessionUserService) {
                    this.user = sessionUserService.get(user);
                } else {
                    this.user = new SessionUser(user);
                }
                this.isAuthenticated = true;
            } else {
                this.user = null;
                this.isAuthenticated = false;
            }
        });
    }

    loginByAccessToken(accessToken: TokenObject, autoLogin = true) {
        return this.tokenService.save(accessToken, autoLogin).then(() => {
            return this.syncUser();
        });
    }

    logout() {
        return this.tokenService
            .clear()
            .then(() => {
                return this.removeSavedUser();
            })
            .then(() => {
                this.user = null;
                this.isAuthenticated = false;
                MemoryCache.clear();
                this.trigger('logout');
            });
    }

    updateLoginedUser() {
        return this.syncUser().catch(ex => {
            this.logger.warn('更新用户信息失败', ex);
            return Promise.reject(ex);
        });
    }
    getLoginedUser<T = any>() {
        return this.user as T;
    }

    async getExternalLogins() {
        if (!this.external_logins) {
            const res = await this.accountsApi.externalLogins().get();
            const { result } = res.data;
            const logins = {};
            for (const login of result) {
                logins[login.login_provider] = login;
            }
            this.external_logins = logins;
        }
        return this.external_logins;
    }

    getExternalLogin(login_provider: 'WeChat' | 'WeChatMiniProgram') {
        return this.getExternalLogins().then(Logins => {
            return Logins[login_provider];
        });
    }

    askLogin(): Promise<AskLoginResult> {
        return this.ready().then(() => {
            return new Promise(resolve => {
                AskCenter.ask('askLogin').then((result: any) => {
                    resolve(result);
                });
            });
        });
    }
}

export default SessionBase;
