import React from 'react';

// import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import di from '@/shared/libs/di';
import SessionService from '@/shared/services/common/session';

import { AppContext } from './context';

interface IProps {
    children: any;
}

// export const cache = createIntlCache();
// const initialLocale = 'en-US';
// const messages: Record<string, Record<string, string>> = {
//     'en-US': en_US,
// };

// export let intl = createIntl({ locale: initialLocale, messages: messages[initialLocale] }, cache);

export const AppProvider: React.FC<IProps> = (props: IProps) => {
    const { children } = props;
    const [state, setState] = React.useReducer(
        (prevState, nextState) => {
            return {
                ...prevState,
                ...nextState,
            };
        },
        {
            isLoading: true,
            isAuthenticated: false,
        },
    );

    // const loginAvChat = () => {
    //     const sessionService = di.getInstance<SessionService>(SessionService);

    //     const avchatService = di.getInstance<CallService>(CallService);
    //     console.log(sessionService.user.id);
    // };

    React.useEffect(() => {
        const bootstrapAsync = async () => {
            const sessionService = di.getInstance<SessionService>(SessionService);
            await sessionService.ready();
            if (sessionService.isAuthenticated) {
                // loginAvChat();
                setState({ isLoading: false, isAuthenticated: true });
            } else {
                setState({ isLoading: false, isAuthenticated: false });
            }
        };
        bootstrapAsync();
    }, []);

    const signIn = React.useCallback(() => {
        // loginAvChat();
        setState({ isLoading: false, isAuthenticated: true });
    }, []);
    const signOut = React.useCallback(() => {
        setState({ isAuthenticated: false });
    }, []);

    return (
        // <RawIntlProvider value={intl}>
        <AppContext.Provider
            value={{
                isLoading: state.isLoading,
                isAuthenticated: state.isAuthenticated,
                signIn,
                signOut,
            }}>
            {children}
        </AppContext.Provider>
        // </RawIntlProvider>
    );
};

export default AppProvider;
