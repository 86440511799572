import di from '../../libs/di';
import HttpFactory from '../../libs/http/factory';
import { api } from '../decorators';

@di.Register('identityApi')
export class IdentityApi {
    @api('identity_api_v1') http: HttpFactory;

    connectToken() {
        return this.http.url('/../connect/token').contentType('application/x-www-form-urlencoded');
    }
}

export default IdentityApi;
