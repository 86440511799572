import React from 'react';

interface IAppContext {
    isLoading?: boolean;
    isAuthenticated?: boolean | null;
    signIn(): any;
    signOut(): any;
}

export const AppContext = React.createContext<IAppContext>({
    isLoading: true,
    isAuthenticated: false,
    signIn() {},
    signOut() {},
});

export default AppContext;
