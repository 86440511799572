import di from '../../../libs/di';
import { Events } from '../../../libs/events';
import storage from '../../../libs/storage';
import { IAsyncKeyValueStorage } from '../../../libs/storage/IAsyncKeyValueStorage';
import { ISyncKeyValueStorage } from '../../../libs/storage/ISyncKeyValueStorage';
import { IConfigService } from './interface';

@di.Register('configService')
export class ConfigService extends Events implements IConfigService {
    @storage.async('appconf_1') confStorageAsync: IAsyncKeyValueStorage;
    @storage.sync('appconf_2') confStorageSync: ISyncKeyValueStorage;

    constructor(private readOnlyConfig) {
        super();
    }

    get<T>(key): T {
        const conf = this.readOnlyConfig[key];
        if (!conf) {
            throw new Error(`未找到配置${key}`);
        }
        return conf;
    }

    private syncConf = {};

    readSync<T>(key, defaultConf: T = null): T {
        let conf = this.syncConf[key];
        if (!conf) {
            conf = this.confStorageSync.getObject(key);
            this.syncConf[key] = conf || defaultConf;
        }
        return conf || defaultConf;
    }

    saveSync<T>(key, value: T) {
        if (this.syncConf[key] != value) {
            this.syncConf[key] = value;
            this.confStorageSync.setObject(key, value);
            this.trigger(`changed:${key}`, value);
        }
        return this.syncConf[key];
    }

    removeSync(key) {
        this.confStorageSync.remove(key);
        delete this.syncConf[key];
    }

    private asyncConf = {};

    async readAsync<T>(key, defaultConf: T = null): Promise<T> {
        let conf = this.asyncConf[key];
        if (!conf) {
            conf = await this.confStorageAsync.getObjectAsync(key);
            this.asyncConf[key] = conf || defaultConf;
        }
        return conf || defaultConf;
    }

    async saveAsync<T>(key, value: T) {
        if (this.asyncConf[key] != value) {
            this.asyncConf[key] = value;
            await this.confStorageAsync.setObjectAsync(key, value);
            this.trigger(`changed:${key}`, value);
        }
        return this.asyncConf[key];
    }

    async removeAsync(key) {
        if (this.asyncConf[key]) {
            await this.confStorageAsync.removeAsync(key);
            delete this.asyncConf[key];
        }
    }
}

export default ConfigService;
